<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">用户领取记录</span>
		</div>
		<div class="bg-white p-2 m-2">
			<el-tabs @tab-click="handleClick" v-model="search.coupon_type">
				<el-tab-pane v-for="(item, index) in tabs" :key="index" :label="item.name" :name="item.value">
				</el-tab-pane>
			</el-tabs>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%">
				<el-table-column align="center" width="120" prop="user_coupon_id" label="优惠券ID"></el-table-column>
				<el-table-column align="center" label="劵类型" width="90">
					<template slot-scope="scope">
						<el-tag size="mini" style="margin-right: 5px;">{{ scope.row.coupon_type.text }}</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" label="劵名称" prop="name"></el-table-column>
				<el-table-column align="center" label="使用条件" prop="condition"></el-table-column>
				<el-table-column align="center" label="有效期">
					<template slot-scope="scope">
						{{scope.row.start_time}} - {{scope.row.end_time}}
					</template>
				</el-table-column>
				<el-table-column align="center" label="领取时间" width="180">
					<template slot-scope="scope">
						<span style="">{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作" width="170">
				</el-table-column>
			</el-table>
			
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				tabs: [{
						name: '全部',
						value: '0'
					},
					{
						name: '兑换券',
						value: '10'
					},
					{
						name: '满减券',
						value: '20'
					},
					{
						name: '折扣卷',
						value: '30'
					},
					{
						name: '代金券',
						value: '40'
					},
					{
						name: '裂变卷',
						value: '50'
					}
				],
				lists: [],
				search: {
					s: 'store/user/coupon',
					coupon_type: '0',
					user_id: this.$route.params.user_id,
					page: 1,
				},
			}
		},
		created() {
			this.getList();
		},
		methods: {
			handleClick() {
				this.toSearch()
			},
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
		},
	}
</script>

<style>

</style>
